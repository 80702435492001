.download-button:hover {
	background: #1752af;
	transition: 0.4s;
	transform: translate(0, 3px);
	transition-delay: all 0.6s ease;
	color: #00ffc3;
}

/* video button pop up
   ----------------*/

.youtube-button {
	width: 90px;
	height: 90px;
	background: #00ffc3;
	border-radius: 50%;
	position: relative;
	box-shadow: 0px 0px 25px 3px #00ffc35d;
}

.youtube-button:hover {
	transition: ease-in-out 0.4s;
	background: #1752af;
	transform: translate(0, 4px);
}

/* triangle */
.youtube-button::after {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-40%) translateY(-50%);
	transform: translateX(-40%) translateY(-50%);
	transform-origin: center center;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 25px solid black;
	z-index: 100;
	-webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
	transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.youtube-button:hover::after {
	border-left: 25px solid #00ffc3;
	transition: ease-in-out 0.4s;
}

/* pulse wave */
.youtube-button:before {
	content: "";
	position: absolute;
	width: 150%;
	height: 150%;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation: pulsate1 1s;
	animation: pulsate1 2s;
	-webkit-animation-direction: forwards;
	animation-direction: forwards;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: steps;
	animation-timing-function: steps;
	opacity: 1;
	border-radius: 50%;
	border: 5px solid #00ffc3;
	top: -30%;
	left: -27%;
	background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
	0% {
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 1;
		box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
			0px 0px 25px 10px rgba(255, 255, 255, 0.75);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
		box-shadow: none;
	}
}

@keyframes pulsate1 {
	0% {
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 1;
		box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
			0px 0px 25px 10px rgba(255, 255, 255, 0.75);
	}
	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1);
		opacity: 0;
		box-shadow: none;
	}
}

@media only screen and (max-width: 1024px){
  .youtube-button{
    transform: scale(0.7);
  }
}
