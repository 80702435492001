.featured{
    /* background: -webkit-gradient(linear, left top, right top, from(#19233A), to(#303A51)); */
    background: linear-gradient(90deg, #19233A 0%, #303A51 100%);
    transition: .4s ease-out;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin-top: 10px;
}

.featured:hover{
    transform: translateY(5px );
}

.featured::before{
    content: '';
    position: absolute;
    background-color: #1752af;
    left: 0;
    width: 3px;
    height: 30%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 10px;
    z-index: -1;
    transition: ease-in .5s;
}


.featured:hover::before{
    width: 100%;
    height: 100%;
}

  .featured:hover .number{
    color: white;
}
