@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

/* font  */
@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@500&family=Rubik+Distressed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate&display=swap");

body {
  overflow-x: hidden;
}

.common {
  font-family: "Syncopate", sans-serif;
}

.hydra {
  font-family: hydra;
}
.hydrat {
  font-family: hyradt;
}

@font-face {
  font-family: hydra;
  src: url("./assets/fonts/SonOfAGlitchItalic-xa3m.ttf");
}

@font-face {
  font-family: hydrat;
  src: url(./assets/fonts/TitilliumWeb-Bold.ttf);
}

.title {
  margin: 0;
}

.subtitle {
  max-width: 576px;
  margin: 36px auto;
  letter-spacing: 0.1em;
  line-height: 1.3em;
}

.countdown-wrapper {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* margin: 0 -8px 0 -8px; */
}

.time-section {
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #8bbb37 !important;
}

.time {
  margin: 0;
  font-size: 30px !important;
  font-weight: 800 !important;
}

.small {
  font-size: 18px !important;
}


@keyframes shrink-grow {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.1);
  }
}

.bubble {
  animation: shrink-grow 0.8s infinite;
}

.bubble:nth-child(2) {
  animation-delay: 0.2s;
}

.bubble:nth-child(3) {
  animation-delay: 0.4s;
}
