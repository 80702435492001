/* draggable.css */
.no-select {
    user-select: none;
  }
  
  .resizer {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: se-resize;
  }
  