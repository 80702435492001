* {
  transition: all 200ms linear;
}

.float {
  padding: 8px;
  color: white;
  background-color: #1d3c3c;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #070b0b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.float:hover {
  background-color: #3abff8;
  transform: scale(1.05);
  font-weight: bold;
}
#youtube {
  background-color: #f00;
}
#youtube:hover {
  background-color: rgba(255, 0, 0, 0.491);
}

#telegramchnl {
  background-color: #3abff8;
}

#twitter {
  background-color: #3abff8;
}
#telegram {
  padding: 8px 16px;
}
